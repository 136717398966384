import { render, staticRenderFns } from "./provincia.vue?vue&type=template&id=395289c6&"
import script from "./provincia.vue?vue&type=script&lang=js&"
export * from "./provincia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports